import { config } from '@config/config';
import Request from '@services/request';

const request = new Request({
  baseURL: `${config.endpoints.cs}`,
  headers: {
    token: window.localStorage.getItem('u') || '',
  },
});

export const restart = () => request.put<string>('/connection/restart');

export const restartV2ray = () => request.put<string>('/change/port');
