import { createContext, useContext } from 'react';
import UserClass from './user';

const modules = {
  user: new UserClass(),
};

const useModule = () => useContext(createContext(modules));

export default useModule;
