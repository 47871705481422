import { config } from '@config/config';
import Request from '@services/request';

const request = new Request({
  baseURL: `${config.endpoints.cs}/user`,
  headers: {
    token: window.localStorage.getItem('u') || '',
  },
});

export interface User {
  username: string;
  accessed_at: string;
}

export interface UserLogin {
  username: string;
  password: string;
}
export const userLogin = (data: UserLogin) => request.post<string>('/login', { data });

export const userLogout = () => request.post('/logout');

// 应用获取
export const userInfo = () => request.get<User>('/info');
