import { User } from '@interface/user';
import { action, makeObservable, observable } from 'mobx';

export default class UserClass {
  constructor() {
    makeObservable(this);
  }

  @observable
    user: User|null = null;

  @observable
    loaded = false;

  @action
    set = (data:User|null) => {
      this.user = data;
      this.loaded = true;
    };

  @action
    setLoaded = () => {
      this.loaded = true;
    };
}
