import { Modal } from 'antd';
import { cloneDeep, merge } from 'lodash-es';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { FC, useCallback, useContext } from 'react';

type Open = boolean;
type DialogOptions = {
  title: string;
  content: FC| null; // 弹窗内容，functional component
};

interface DialogState {
  open: Open;
  dialogOptions: DialogOptions;
  setOpen: (flag: boolean) => void;
  setDialogOptions: (options: DialogOptions) => void;
}

const noop = () => { };

const defaultState: DialogState = {
  open: false,
  setOpen: noop,
  dialogOptions: {
    title: '',
    content: null,
  },
  setDialogOptions: noop,
};
export type ShowDialog = (options: DialogOptions) => Promise<[boolean, string]>| void;
export type ToggleDialog = (options: DialogOptions) => Promise<[boolean, string]> | void;
export type HideDialog = () => void;

const DialogContext = React.createContext<DialogState>(cloneDeep(defaultState));

const DialogProvider: FC = function DialogProvider({ children }) {
  const state = useLocalObservable<DialogState>(() => ({
    resolveFunc: noop,
    open: defaultState.open,
    dialogOptions: cloneDeep(defaultState.dialogOptions),
    setOpen(flag) {
      state.open = !!flag;
    },
    setDialogOptions(options) {
      state.dialogOptions = cloneDeep(defaultState.dialogOptions);
      merge(state.dialogOptions, options);
    },
  }));

  const DialogComponent = observer(() => (
    <Modal
      width={800}
      title={state.dialogOptions.title}
      centered
      visible={state.open}
      footer={null}
      onCancel={() => state.setOpen(false)}
      bodyStyle={{
        height: '60vh',
        overflowY: 'auto',
      }}
    >
      {state.dialogOptions.content && <state.dialogOptions.content />}
    </Modal>
  ));

  return (
    <DialogContext.Provider value={state}>
      {children}
      <DialogComponent />
    </DialogContext.Provider>
  );
};

export const useCustomDialog = () => {
  const {
    setDialogOptions, setOpen, open,
  } = useContext(DialogContext);

  const showDialog: ShowDialog = useCallback((options: DialogOptions) => {
    setDialogOptions(options);
    setOpen(true);
  }, [setDialogOptions, setOpen]);

  const hideDialog: HideDialog = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const toggleDialog: ToggleDialog = useCallback((options: DialogOptions) => {
    if (open) {
      return hideDialog();
    }
    return showDialog(options);
  }, [hideDialog, open, showDialog]);

  return {
    showDialog,
    hideDialog,
    toggleDialog,
  };
};

export default DialogProvider;
