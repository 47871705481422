import { CSSProperties } from 'react';

export const fullCenter: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

export const loadingCSS: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  position: 'fixed',
  zIndex: 9999,
  background: 'rgba(0, 0, 0, .05)',
};

export const editHeader: CSSProperties = {
  padding: '16px 24px 0 24px',
};

export const formWrapper: CSSProperties = {
  overflowX: 'auto',
  padding: '16px 24px',
};

export const ctrlWrapper: CSSProperties = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 24px',
};

export const tableWrapper: CSSProperties = {
  padding: '16px 24px',
  flex: 1,
  overflowY: 'auto',
};

export const success:CSSProperties = {
  color: '#52c41a',
};

export const info:CSSProperties = {
  color: '#1890ff',
};

export const warn:CSSProperties = {
  color: '#faad14',
};

export const error:CSSProperties = {
  color: '#ff4d4f',
};
