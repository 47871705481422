const config = {
  app: {
    name: process.env.REACT_APP_NAME,
    key: 'cloud.web',
  },
  endpoints: {
    cs: 'http://127.0.0.1:52000',
  },
};

export default config;

export type Config = typeof config &
  typeof import('./testing').default &
  typeof import('./production').default;
