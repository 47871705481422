import { Login } from '@containers/login';
import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { Index } from './containers';

export const routes: RouteObject[] = [
  {
    path: '/',
    children: [
      { index: true, element: <Navigate to="dashboard" /> },
      {
        path: 'dashboard/*',
        element: <Index />,
      },
      {
        path: 'login/*',
        element: <Login />,
      },
    ],
  },
];
