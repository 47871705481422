import { CloseCircleOutlined, RedoOutlined } from '@ant-design/icons';
import { error, fullCenter, info } from '@common/jss';
import { restart, restartV2ray } from '@interface/connection';
import { Connection, connectionGet } from '@interface/public';
import { useUserInfo } from '@store/hooks/user';
import { Util } from '@typing/util';
import {
  Button, Card, Col, Modal, PageHeader, Row, Spin, message,
} from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import dayjs from 'dayjs';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

interface State {
  loading: boolean;
  data: null | Connection;
  refreshCount: number;
  updated_at: string;
  set: Util.SetPickProp<State, 'loading' | 'data' | 'updated_at'>;
  setRefreshCount: () => void;
}

export const Index: FC = observer(() => {
  const { user, loaded } = useUserInfo();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const state = useLocalObservable<State>(() => ({
    loading: false,
    data: null,
    refreshCount: 0,
    updated_at: '',
    set(key, value) {
      state[key] = value;
    },
    setRefreshCount() {
      state.refreshCount += 1;
    },
  }));

  const { set, setRefreshCount } = state;

  // 打开登录页面
  const handleLogin = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  // 重启二次确认弹框
  const handleRestart = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  // 关闭二次确认弹框
  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  // 确认发送重启指令
  const handleOk = useCallback(() => {
    setIsModalVisible(false);
    restart()
      .then(() => {
        message.success('操作成功');
      })
      .catch(() => {
        message.error('操作失败');
      })
      .finally(() => {
        setRefreshCount();
      });
  }, [setRefreshCount]);

  const handleRestartV2ray = useCallback(() => {
    restartV2ray()
      .then(() => {
        message.success('操作成功');
      })
      .catch(() => {
        message.error('操作失败');
      });
  }, []);

  // 触发数据刷新
  const handleRefresh = useCallback(() => {
    setRefreshCount();
  }, [setRefreshCount]);

  // 获取状态数据
  useEffect(() => {
    set('loading', true);
    connectionGet()
      .then(({ data }) => {
        set('data', data);
        set('updated_at', dayjs().format('HH:mm:ss'));
      })
      .catch(() => {
        message.error('数据获取异常');
      })
      .finally(() => {
        set('loading', false);
      });
  }, [set, state.refreshCount]);

  // 10s自动刷新
  useEffect(() => {
    if (!state.data) return;
    const t = setTimeout(() => {
      setRefreshCount();
    }, 10000);
    // eslint-disable-next-line consistent-return
    return function cleanup() {
      clearTimeout(t);
    };
  }, [setRefreshCount, state.data]);

  return (
    <>
      {loaded && !user && (
        <div style={fullCenter}>
          <Button onClick={handleLogin} type="primary">
            去登录
          </Button>
        </div>
      )}
      {!loaded && <Spin style={fullCenter} />}
      {user && state.data && (
        <>
          <PageHeader
            title="云控平台"
            extra={[
              <b key="1" style={{ fontSize: 12, fontWeight: 'normal' }}>
                {state.updated_at}
              </b>,
              <Button key="2" onClick={handleRefresh} type="primary">
                <RedoOutlined />
              </Button>,
            ]}
          />
          <Row gutter={24}>
            <Col span={12}>
              <Card title="用户" bordered={false}>
                <Paragraph>
                  用户：
                  {user.username}
                </Paragraph>
                <Paragraph>最近访问：</Paragraph>
                <Paragraph style={{ fontSize: 12 }}>
                  {dayjs(parseInt(user.accessed_at, 10)).format(
                    'YYYY-MM-DD HH:mm:ss',
                  )}
                </Paragraph>
              </Card>
            </Col>
            <Col span={12}>
              <Card title="谷歌连通性" bordered={false}>
                <Paragraph>
                  状态：
                  {parseFloat(state.data.wireguard['router.google.timeout'])
                  < 0 ? (
                    <CloseCircleOutlined style={error} />
                    ) : (
                      `${parseFloat(
                        state.data.wireguard['router.google.timeout'],
                      ).toFixed(2)}ms`
                    )}
                </Paragraph>
                <Paragraph>检查时间：</Paragraph>
                <Paragraph style={{ fontSize: 12 }}>
                  {dayjs(
                    parseInt(
                      state.data.wireguard['router.google.timeout.updated_at'],
                      10,
                    ),
                  ).format('YYYY-MM-DD HH:mm:ss')}
                </Paragraph>
              </Card>
            </Col>
            <Col span={12}>
              <Card title="远程WG端口" bordered={false}>
                <Paragraph>
                  端口：
                  <b style={info}>{state.data.wireguard['vultr.wg.port']}</b>
                </Paragraph>
                <Paragraph>上报时间：</Paragraph>
                <Paragraph style={{ fontSize: 12 }}>
                  {dayjs(
                    parseInt(
                      state.data.wireguard['vultr.wg.port.updated_at'],
                      10,
                    ),
                  ).format('YYYY-MM-DD HH:mm:ss')}
                </Paragraph>
              </Card>
            </Col>
            <Col span={12}>
              <Card title="Google IP" bordered={false}>
                <Paragraph>{state.data.wireguard['vultr.google.ip']}</Paragraph>
                <Paragraph>上报时间：</Paragraph>
                <Paragraph style={{ fontSize: 12 }}>
                  {dayjs(
                    parseInt(
                      state.data.wireguard['vultr.google.ip.updated_at'],
                      10,
                    ),
                  ).format('YYYY-MM-DD HH:mm:ss')}
                </Paragraph>
              </Card>
            </Col>
            <Col span={24} style={{ padding: '0 32px 32px 32px' }}>
              {state.data.wireguard.signal === '1' ? (
                <Button disabled type="primary" block>
                  重启中...
                </Button>
              ) : (
                <Button onClick={handleRestart} type="primary" block>
                  发送重启指令
                </Button>
              )}
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Card title="V2ray管理" bordered={false}>
                <Paragraph>
                  端口：
                  <b style={info}>{state.data.v2ray['v2ray.port']}</b>
                </Paragraph>
                <Paragraph>
                  上报时间：
                  {dayjs(
                    parseInt(state.data.v2ray['v2ray.port.updated_at'], 10),
                  ).format('YYYY-MM-DD HH:mm:ss')}
                </Paragraph>
              </Card>
            </Col>
            <Col span={24} style={{ padding: '0 32px 32px 32px' }}>
              {state.data.v2ray.signal === '1' ? (
                <Button disabled type="primary" block>
                  重启中...
                </Button>
              ) : (
                <Button onClick={handleRestartV2ray} type="primary" block>
                  发送重启指令
                </Button>
              )}
            </Col>
          </Row>
        </>
      )}
      <Modal
        title="请确认操作"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>点击确定后，将发送重启指令</p>
        <p>Vultr检测到该状态，将重启WG，并上报新端口</p>
        <p>路由器检测到端口变化，将重启WG</p>
      </Modal>
    </>
  );
});
