import { userInfo } from '@interface/user';
import useModule from '@store/data';
import { useEffect } from 'react';

export const useUserInfo = () => {
  const {
    user: {
      user, loaded, set, setLoaded,
    },
  } = useModule();

  useEffect(() => {
    if (loaded) return;
    userInfo().then(({ data }) => {
      set(data);
    }).finally(() => {
      setLoaded();
    });
  }, [loaded, set, setLoaded]);

  return {
    user,
    loaded,
  };
};
