import '@common/common.less';
import CustomDialog from '@provider/CustomDialog';
import { ConfigProvider } from 'antd';
import 'antd/dist/antd.min.css';
import zhCN from 'antd/lib/locale/zh_CN';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <BrowserRouter>
      <CustomDialog>
        <App />
      </CustomDialog>
    </BrowserRouter>
  </ConfigProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log);
