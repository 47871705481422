import { config } from '@config/config';
import Request from '@services/request';

const request = new Request({
  baseURL: `${config.endpoints.cs}/public`,
  headers: {
    token: window.localStorage.getItem('u') || '',
  },
});

export interface Connection {
  wireguard: {
    'router.google.timeout': string;
    'router.google.timeout.updated_at': string;
    'vultr.google.ip': string;
    'vultr.google.ip.updated_at': string;
    'vultr.wg.port': string;
    'vultr.wg.port.updated_at': string;
    signal: string;
  };
  v2ray: {
    'v2ray.port': string;
    'v2ray.port.updated_at': string;
    signal: string;
  };
}

export const connectionGet = () => request.get<Connection>('/connection');
