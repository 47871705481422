import { fullCenter } from '@common/jss';
import { userLogin, UserLogin } from '@interface/user';
import { useUserInfo } from '@store/hooks/user';
import {
  Button, Col, Form, Input, message, Row, Spin,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Login:FC = observer(() => {
  const { user, loaded } = useUserInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loaded) return;

    if (user && user.username) {
      navigate('/');
    }
    if (!user) {
      message.error('请登录账号');
    }
  }, [navigate, user, loaded]);

  const onFinish = useCallback((values:UserLogin) => {
    userLogin(values).then(({ data }) => {
      window.localStorage.setItem('u', data);
      window.location.reload();
    }).catch(() => {
      message.error('账号/密码错误，或IP受限');
    });
  }, []);

  return (
    <>
      {!loaded && (<Spin style={fullCenter} />)}
      {loaded && !user && (
        <Row
          gutter={{
            xs: 8, sm: 16, md: 24, lg: 32,
          }}
          style={fullCenter}
        >
          <Col>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              onFinish={onFinish}
            >
              <Title>云控平台</Title>
              <Form.Item
                label="用户"
                name="username"
                rules={[{ required: true, message: '请输入用户名' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="密码"
                name="password"
                rules={[{ required: true, message: '请输入密码' }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item wrapperCol={{ span: 24 }}>
                <Button type="primary" htmlType="submit" block>
                  登录
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}
    </>

  );
});
